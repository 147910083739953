import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserRound, Menu, X } from 'lucide-react';
import { useUnreadMessages } from '../contexts/UnreadMessagesContext';
import config from '../contexts/config';
import { getAccessToken } from '../contexts/supabaseClient';

const Navbar = () => {
  const { currentUser, logout } = useAuth();
  const { totalUnreadMessages } = useUnreadMessages();
  const navigate = useNavigate();
  const profileMenuRef = useRef(null);
  const location = useLocation();
  const isPaymentPage = ['/payment', '/create-stripe-account', '/stripe/refresh/:connectedAccountId', '/return/:connectedAccountId', '/my-account-details'].includes(location.pathname);

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [dashboardNotifications, setDashboardNotifications] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(0);

  const toggleMobileMenu = useCallback(() => setMobileMenuOpen((prev) => !prev), []);

  const handleLogout = useCallback(() => {
    logout();
    navigate('/');
    setShowProfileMenu(false);
  }, [logout, navigate]);

  const handleClickOutside = useCallback((event) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
      setShowProfileMenu(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const fetchNavbarData = useCallback(async () => {
    if (!currentUser) return;

    const now = Date.now();
    if (now - lastFetchTime < 60000) return; // Throttle requests to 1 minute

    try {
      const token = await getAccessToken();

      const [ordersResponse, jobsResponse] = await Promise.all([
        axios.get(`${config.API_URL}/stripe/my-orders`, {
          params: { user_id: currentUser.id },
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`${config.API_URL}/stripe/stripe/my-jobs`, {
          params: { user_id: currentUser.id },
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const pendingOrders = ordersResponse.data.filter(
        (order) => order.status === 'Pending payment' || order.status === 'Pending approval'
      );

      const dashboardOrders = jobsResponse.data.filter(
        (order) => order.status === 'Booking Requested' || order.status === 'Paid'
      );

      setPendingOrdersCount(pendingOrders.length);
      setDashboardNotifications(dashboardOrders.length);
      setLastFetchTime(now);
    } catch (error) {
      console.error('Error fetching navbar data:', error);
    }
  }, [currentUser, lastFetchTime]);

  useEffect(() => {
    if (currentUser && isPaymentPage) {
      fetchNavbarData();
    }
  }, [currentUser, isPaymentPage, fetchNavbarData]);

  const MenuItems = useMemo(
    () => {
      const toggleProfileMenu = () => setShowProfileMenu((prev) => !prev);

      return ({ isMobile = false }) => (
        <>
          <li>
            <Link to="/browse" className="head" onClick={() => isMobile && toggleMobileMenu()}>
              Browse Services
            </Link>
          </li>
          {currentUser ? (
            <li className="profile-menu-container">
              {!isMobile && (
                <button onClick={toggleProfileMenu} className="icon-button">
                  My menu <UserRound size={24} />
                  {totalUnreadMessages > 0 && <span className="profile-notification"></span>}
                </button>
              )}
              {(showProfileMenu || isMobile) && (
                <div className="profile-menu" ref={profileMenuRef}>
                  <Link
                    to="/profile"
                    onClick={() => {
                      setShowProfileMenu(false);
                      isMobile && toggleMobileMenu();
                    }}
                  >
                    Profile
                  </Link>
                  <button
                    onClick={() => {
                      navigate(`/messages`);
                      isMobile && toggleMobileMenu();
                    }}
                    className="dropdown-button"
                  >
                    Messages
                    {totalUnreadMessages > 0 && (
                      <span className="message-notification">{totalUnreadMessages}</span>
                    )}
                  </button>
                  <Link
                    to="/my-orders"
                    onClick={() => {
                      setShowProfileMenu(false);
                      isMobile && toggleMobileMenu();
                    }}
                  >
                    My Orders {pendingOrdersCount > 0 && `(${pendingOrdersCount})`}
                  </Link>
                  <Link
                    to="/dashboard"
                    onClick={() => {
                      setShowProfileMenu(false);
                      isMobile && toggleMobileMenu();
                    }}
                  >
                    Service Dashboard {dashboardNotifications > 0 && `(${dashboardNotifications})`}
                  </Link>
                  <Link
                    to="/my-account-details"
                    onClick={() => {
                      setShowProfileMenu(false);
                      isMobile && toggleMobileMenu();
                    }}
                  >
                    Account Details
                  </Link>
                  <button onClick={handleLogout} style={{ fontWeight: 'bold' }}>
                    Logout
                  </button>
                </div>
              )}
            </li>
          ) : (
            <li className="login">
              <Link to="/login" className="login" onClick={() => isMobile && toggleMobileMenu()}>
                Login
              </Link>
            </li>
          )}
        </>
      );
    },
    [currentUser, totalUnreadMessages, pendingOrdersCount, dashboardNotifications, navigate, handleLogout, toggleMobileMenu, showProfileMenu]
  );

  return (
    <header>
      <nav>
        <Link to="/">
          <img src="/images/Quic.avif" alt="Logo" />
        </Link>
        <div className="hamburger-menu-container">
          <div className="hamburger-menu" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </div>
          {!mobileMenuOpen && totalUnreadMessages > 0 && <span className="mobile-notification"></span>}
        </div>
        <ul className="desktop-menu">
          <MenuItems />
        </ul>
        {mobileMenuOpen && (
          <div className="mobile-menu">
            <ul>
              <MenuItems isMobile={true} />
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default React.memo(Navbar);