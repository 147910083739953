import React, { createContext, useState, useContext } from 'react';

const OrderContext = createContext();

export const useOrder = () => useContext(OrderContext);

export const OrderProvider = ({ children }) => {
  const [orderedServiceId, setOrderedServiceId] = useState(null);

  const initiateOrder = (serviceId) => {
    return new Promise((resolve) => {
      console.log(`Setting orderedServiceId to ${serviceId}`);
      setOrderedServiceId(serviceId);
      resolve();
    });
  };

  const resetOrder = () => {
    console.log('Resetting orderedServiceId'); 
    setOrderedServiceId(null);
  };

  return (
    <OrderContext.Provider value={{ orderedServiceId, initiateOrder, resetOrder }}>
      {children}
    </OrderContext.Provider>
  );
};