import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { UnreadMessagesProvider } from './contexts/UnreadMessagesContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <UnreadMessagesProvider> 
          <App />
        </UnreadMessagesProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);