export function generateProductSlug(serviceName, id) {
  const slug = serviceName
    .toLowerCase()
    .replace(/ /g, '-')  
    .replace(/[^\w-]+/g, ''); 
  return `${slug}/${id}`; 
}

export function generateUserProfileUrl(userName, userId) {
  if (!userName || !userId) {
    return '#';
  }
  const slug = userName
    .toLowerCase()
    .replace(/\s+/g, '-') 
    .replace(/[^\w-]+/g, ''); 
  return `/view-user-profile/${slug}/${userId}`;
}

export function hashConversationId(conversationId) {
  let hash = 0;
  for (let i = 0; i < conversationId.length; i++) {
    const char = conversationId.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; 
  }
  return Math.abs(hash).toString(16);
}
