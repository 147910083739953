import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import config from './config';
import { supabase, getAccessToken, isTokenExpired } from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [status, setStatus] = useState('loading');  

  const updateAuthState = useCallback((user) => {
    setCurrentUser(user);
    setIsAuthenticated(!!user);
    setLoading(false);
    setStatus(user ? 'authenticated' : 'unauthenticated');
  }, []);

  const logout = useCallback(async () => {
    try {
      await supabase.auth.signOut();
      updateAuthState(null);
      setProfile(null);
      setToken(null);
      localStorage.removeItem('supabase.auth.token');
      setStatus('unauthenticated');  // Set status explicitly on logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [updateAuthState]);

  const fetchUserProfile = useCallback(async (user) => {
    if (!user) return;
    try {
      const token = await getAccessToken();
      const response = await axios.get(`${config.API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;

      updateAuthState(data.user);  // Ensure this updates the auth state properly
      localStorage.setItem('supabase.auth.token', JSON.stringify(data.session));
      await fetchUserProfile(data.user);
      return data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const fetchUsers = async () => {
    try {
      const token = await getAccessToken();
      const response = await axios.get(`${config.API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error.response?.data || error.message);
      throw error;
    }
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      try {
        setLoading(true); // Set loading to true while resolving auth state
        if (session?.user) {
          updateAuthState(session.user);
          setToken(session.access_token);
          await fetchUserProfile(session.user);
          setStatus('authenticated');
        } else {
          updateAuthState(null);
          setProfile(null);
          setToken(null);
          setStatus('unauthenticated');
        }
      } catch (error) {
        console.error('Error in onAuthStateChange:', error);
        setStatus('unauthenticated');
        setLoading(false);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [fetchUserProfile, updateAuthState]);

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const { data, error } = await supabase.auth.refreshSession();
        if (error || !data.session) {
          console.error('Token refresh failed:', error);
          await logout();
          setStatus('unauthenticated');
        } else {
          setCurrentUser(data.user);
          setStatus('authenticated');
          localStorage.setItem('supabase.auth.token', JSON.stringify(data.session));
        }
      } catch (err) {
        console.error('Failed to refresh session:', err);
        await logout();
        setStatus('unauthenticated');
      }
    };

    const interceptor = axios.interceptors.request.use(
      async (config) => {
        const token = await getAccessToken();
        if (token && !isTokenExpired(token)) {
          config.headers['Authorization'] = `Bearer ${token}`;
        } else {
          await refreshToken();
          const newToken = await getAccessToken();
          if (newToken) {
            config.headers['Authorization'] = `Bearer ${newToken}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [logout]);

  return (
    <AuthContext.Provider value={{ 
      currentUser, 
      userId: currentUser?.id, 
      profile, 
      loading,
      token,
      isAuthenticated,
      status,
      login, 
      logout,
      fetchUserProfile,
      fetchUsers
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;