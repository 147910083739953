import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = ({ children }) => {
  const location = useLocation();

  const hideFooterPaths = [
    '/welcome', 
    '/messages', 
    '/messages/:conversationId', 
    '/payment', 
    '/payment-result', 
    '/service-summary', 
    '/service-reviews', 
    '/my-jobs'
  ];

  const showFooter = !hideFooterPaths.some((path) =>
    new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`).test(location.pathname)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="layout">
      <Navbar />
      <main className="main-content">
        <div className="content-wrapper">
          {children}
        </div>
      </main>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;