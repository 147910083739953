import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Supabase URL or Key is missing. Please check your environment variables.');
}

export const supabase = createClient(supabaseUrl, supabaseKey, {
  realtime: {
    params: {
      eventsPerSecond: 10,
    },
  },
});

export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) throw error;
  localStorage.setItem('supabase.auth.token', JSON.stringify(data.session));
  return data;
};

export const signUp = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({ email, password });
  if (error) throw error;
  return data;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
  localStorage.removeItem('supabase.auth.token');
};

export const resetPassword = async (email) => {
  const { error } = await supabase.auth.api.resetPasswordForEmail(email);
  if (error) throw error;
};

export const getCurrentSession = async () => {
  const { data, error } = await supabase.auth.getSession();
  if (error) throw error;
  return data.session;
};

export const getCurrentUser = async () => {
  const { data, error } = await supabase.auth.getUser();
  if (error) {
    console.error('Error fetching user:', error);
    return null;
  }
  return data.user;
};

export const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Error parsing token:', error);
    return true;
  }
};

export const getAccessToken = async () => {
  const storedSession = localStorage.getItem('supabase.auth.token');
  if (storedSession) {
    const session = JSON.parse(storedSession);
    if (session?.access_token && !isTokenExpired(session.access_token)) {
      return session.access_token;
    }
  }

  const { data: { session }, error } = await supabase.auth.getSession();
  if (error || !session) {
    console.error('Error fetching session:', error);
    return null;
  }

  if (session?.access_token && !isTokenExpired(session.access_token)) {
    localStorage.setItem('supabase.auth.token', JSON.stringify(session));
    return session.access_token;
  }

  console.log('Token is expired or invalid.');
  return null;
};

const checkTable = async (tableName) => {
  try {
    const { count, error } = await supabase
      .from(tableName)
      .select('count', { count: 'exact', head: true });

    if (error) {
      console.error(`Error checking ${tableName} table:`, error);
    } else {
      console.log(`${tableName} table exists. Row count:`, count);
    }
  } catch (error) {
    console.error(`Failed to check ${tableName} table:`, error);
  }
};

if (process.env.NODE_ENV !== 'production') {
  Promise.all([checkTable('conversations'), checkTable('messages')])
    .then(() => console.log('Database check complete'))
    .catch(error => console.error('Error during database check:', error));
};