import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const today = new Date();

    return (
        <footer className="quic-footer">
            <div className="quic-footer-content">
                <div className="quic-footer-main">
                    <h3>Quic</h3>
                    <span>Odd jobs. Small jobs. Quic jobs.</span>
                </div>
                <div className="quic-footer-links">
                    <div className="quic-footer-column">
                        <h6>Quick Links</h6>
                        <ul>
                            <li><Link to="/add-service">Add a listing</Link></li>
                            <li><Link to="/how-it-works">How it works</Link></li>    
                            <li><Link to="/browse">Browse available services</Link></li>
                            <li><Link to="/dashboard">Service dashboard</Link></li>
                            {/* <li><Link to="/community-guidelines">Community guidelines</Link></li> */}
                        </ul>
                    </div>
                    <div className="quic-footer-column">
                        <h6>Company</h6>
                        <ul>
                            <li><Link to="/about-quic">About</Link></li>
                            <li><Link to="/feedback-form">Feedback</Link></li>
                            <li><a href="mailto:hello@quicjobs.co.uk">Get in touch</a></li>
                            <li><Link to="/terms-of-services">Terms of Services</Link></li>
                            <li><Link to="/privacy-policy">Privacy policy</Link></li>
                        </ul>
                    </div>
                    <div className="quic-footer-column">
                        <h6>Social</h6>
                        <ul>
                            <li><a href="https://www.facebook.com/people/Quic-jobs-UK/61556206433536/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://www.instagram.com/quicjobs_uk" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            <li><a href="https://www.x.com/quic_jobs" target="_blank" rel="noopener noreferrer">X</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="quic-footer-bottom">
                <p>&copy; {today.getFullYear()} Quic Jobs Limited. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;